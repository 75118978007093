import * as React from 'react';
import { graphql, Link } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CDefinition,
  ConceptMedia,
  LContact,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: (
              <>
                BABYSITTING <br />
                SERVICE
              </>
            ),
            sub: 'ベビーシッターサービス',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/facilities/baby/kv.png',
              },
              imgSp: {
                src: '/assets/images/facilities/baby/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '館内施設・サービス',
                path: '/facilities/',
              },
            ],
            current: {
              label: 'ベビーシッターサービス',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect">
        <LWrap>
          <p className="c_sectLead u_mb15">
            ベビーシッターがホテルに出張して客室にてお子様をお預かりいたします。
            <br />
            詳しくはお問合せください。
          </p>
          <ul className="c_noteList u_mb40 u_tac_pc">
            <li>
              ベビーシッターは株式会社アルファコーポレーション（
              <Link
                to="https://www.alpha-co.com"
                target="_blank"
                rel="noopner noreferrer"
              >
                www.alpha-co.com
              </Link>
              ）より派遣されます。
            </li>
            <li>
              生後3か月以上のお子様を2時間以上30分単位でお預かりいたします。
            </li>
          </ul>
          <CDefinition
            exClass="u_mb20"
            data={[
              {
                title: '予約',
                text: (
                  <>
                    要予約（3営業日前17:00まで）
                    <br />
                    <ul className="c_noteList u_mb40">
                      <li>営業日は、株式会社アルファコーポレーションに準じます。</li>
                    </ul>
                  </>
                ),
              },
              {
                title: 'ご予約・お問合せ',
                text: (
                  <>
                    ホテルコンシェルジュデスク
                    <br />
                    <a href="tel:0452211111">TEL 045-221-1111</a>（代表経由）
                    <br />
                    受付時間 10:00〜17:00
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      {/* <LContact
        tel={[
          {
            title: '代表',
            number: '045-221-1111',
          },
        ]}
      /> */}
      {/* <ConceptMedia /> */}
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
